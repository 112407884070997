<template>
  <div class="examine_detail">
    <back />
    <div class="content globle_border">
      <el-form class="form" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <div class="title1">添加学校信息</div>
        <el-form-item label="学校名称" prop="schoolName">
          <el-input v-model="ruleForm.schoolName" placeholder="请输入学校名称"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contactMan">
          <el-input v-model="ruleForm.contactMan" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactTel">
          <el-input v-model="ruleForm.contactTel" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="学校地区" prop="selectedOptions">
          <el-cascader ref="cascader" v-model="ruleForm.selectedOptions" :options="addressList" :props="regionParams" placeholder="请选择地区" style="width: 100%" @change="addressChoose"></el-cascader>
        </el-form-item>
        <el-form-item label="学校地址" prop="address">
          <el-input type="textarea" v-model="ruleForm.address" maxlength="100" show-word-limit :autosize="{ minRows: 2, maxRows: 5 }" placeholder="请输入学校地址"></el-input>
        </el-form-item>
        <el-form-item label="学校公告">
          <div class="Runecloth">
            <VueEditor :config="config" ref="vueEditor" />
          </div>
        </el-form-item>
        <el-form-item class="footer">
          <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import VueEditor from "vue-word-editor"
import "quill/dist/quill.snow.css"
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      id: null,
      regionParams: { label: "name", value: "code", expandTrigger: "hover" },
      // options: require('../../../assets/pca-code.json'), // 省市区数据(三维数组)
      addressList: [],

      ruleForm: {
        schoolName: "",
        contactMan: "",
        contactTel: "",
        address: "",
        remark: "", // 学校公告
        selectedOptions: [], // 选择的地区
        province: "", // 省
        city: "", // 市
        area: "", // 区
        provinceCode: "", // 省
        cityCode: "", // 市
        areaCode: "", // 区
      },
      rules: {
        schoolName: [{ required: true, message: "请输入学校名称", trigger: "blur" }],
        contactMan: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        selectedOptions: [{ required: true, message: "缺少所在地区", trigger: "change" }],
        contactTel: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { pattern: /^1[3-9]\d{9}$/, message: "请输入有效的手机号码", trigger: "blur" },
        ],
        address: [{ required: true, message: "请输入学校地址", trigger: "blur" }],
      },

      config: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            ["link", "image"],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction
          ],
          //  readOnly: true,
        },
        // 上传图片的配置
        uploadImage: {
          url: this.$axios.defaults.baseURL + this.$api.uploadFile,
          name: "multipartFile",
          // res是结果，insert方法会把内容注入到编辑器中，res.data.url是资源地址
          uploadSuccess: (res, insert) => {
            if (res.data.code !== 100) {
              this.$message.error("请上传图片格式,如JPG,JPEG,PNG文件格式")
            } else {
              insert(res.data.result.fileUrl)
            }
          },
          showProgress: false,
        },
      },
    }
  },
  created() {
    this.getAddress()
    this.id = this.$route.query.id
  },
  methods: {
    getAddress() {
      this.$axios.get(this.$api.getDistrictInfo).then(res => {
        if (res.data.code == 100) {
          // console.log(res);
          this.addressList = res.data.result
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.ruleForm.remark = this.$refs.vueEditor.editor.root.innerHTML
          this.$axios.post(this.$api.schoolAdd, this.ruleForm).then(res => {
            if (res.data.code == 100) {
              this.$message({
                message: "新增成功",
                type: "success",
              })
              this.$router.replace("/school/list")
            }
          })
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.$router.replace("/school/list")
    },
    // 选择省市区
    addressChoose(value) {
      // 注意：path、pathLabels为getCheckedNodes自带的键名
      // let codes = this.$refs['cascader'].getCheckedNodes()[0].path
      let names = this.$refs["cascader"].getCheckedNodes()[0].pathLabels
      // console.log(names);
      this.ruleForm.province = names[0]
      this.ruleForm.city = names[1]
      this.ruleForm.area = names[2]
      this.ruleForm.provinceCode = value[0]
      this.ruleForm.cityCode = value[1]
      this.ruleForm.areaCode = value[2]
    },
  },
}
</script>

<style scoped lang="scss">
.examine_detail {
  .content {
    padding: 60px 190px;

    .form {
      .Runecloth {
        margin-left: 50px;
        width: 734px;
      }

      .footer {
        //   margin-top: 50px;
        text-align: center;
      }

      .title1,
      .title2 {
        position: relative;
        font-weight: bold;
        margin-bottom: 40px;
      }

      .title1::before,
      .title2::before {
        content: "";
        position: absolute;
        top: 0;
        left: -12px;
        width: 4px;
        height: 22px;
        background-color: #c27c88;
      }

      .title2 {
        margin-top: 60px;
      }

      ::v-deep .el-form-item__content {
        margin-bottom: 30px;
      }

      ::v-deep .el-input {
        width: 650px;
        margin-left: 50px;
      }

      ::v-deep .el-form-item__error {
        margin-left: 50px;
      }

      ::v-deep .el-form-item__label {
        color: #272727;
        font-size: 16px;
      }

      ::v-deep .el-textarea {
        width: 650px;
        margin-left: 50px;
      }

      ::v-deep .el-input__inner,
      ::v-deep .el-textarea__inner {
        background: #fff;
        border-radius: 10px;
        border: 1px solid #e5e7e6;
      }

      ::v-deep .el-textarea .el-input__count {
        background: transparent !important;
        bottom: -8px;
        font-size: 10px;
      }

      ::v-deep .el-input.is-disabled .el-input__inner,
      ::v-deep .el-textarea.is-disabled .el-textarea__inner {
        color: #1a1a1a;
      }
    }
  }
}
</style>
